/* TODO split into smaller files */
.form-grid {
	display: grid;
	column-gap: 0px;
	row-gap: 0px;
}

.form-inline-grid {
	display: inline-grid;
	column-gap: 0px;
	row-gap: 0px;
}

fieldset.oldfieldset {
	border: 1px groove #ddd !important;
	padding: 0 1.4em 1.4em 1.4em !important;
	margin: 1.5em !important;
	-webkit-box-shadow:  0px 0px 0px 0px #000;
	box-shadow:  0px 0px 0px 0px #000;
}

legend.oldlegend {
	font-size: 1.2em !important;
	font-weight: bold !important;
	text-align: left !important;
	width:auto;
	padding:0 10px;
	border-bottom:none;
}

/* Form components */

.ps-form-dialog {
	.ps-btn-default, .ps-btn-primary, .ps-btn-danger, .ps-btn-success, .ps-btn-warning {
		text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
		background-image: linear-gradient(135deg, rgba(255,255,255,0.5), transparent);
		box-shadow: inset 0px -2px 1px 0px rgba(0,0,0,0.5), 0px 4px 4px -2px rgba(0,0,0,0.5);
		margin: 0.2em;
	}
	.ps-btn-default:hover, .ps-btn-primary:hover, .ps-btn-danger:hover, .ps-btn-success:hover, .ps-btn-warning:hover {
		filter: brightness(1.1);
	}
}

form.ps-form {
	h1.ps-form-heading {
		text-align:center;
	}

	.ps-input.valid {
		box-shadow: inset 0px -2px 0px -1px var(--bg-success);
	}

	.ps-input.invalid {
		box-shadow: inset 0px -2px 0px -1px var(--bg-danger);
	}

	.ps-input.warning {
		box-shadow: inset 0px -2px 0px -1px var(--bg-warning);
	}

	.ps-datetime-picker {
		display:inline-block; 
		border-radius: 0.5em;
	}

	.ps-datetime-picker.valid {
		box-shadow: inset 0px -3px 0px -1px var(--bg-success);
	}

	.ps-datetime-picker.invalid {
		box-shadow: inset 0px -3px 0px -1px var(--bg-danger);
	}

	.ps-datetime-picker.warning {
		box-shadow: inset 0px -3px 0px -1px var(--bg-warning);
	}
	

	div.ps-form-group {
		box-shadow: inset 0px 0px 5px 0px lightgray;
		padding: 1em;
		border-radius: 10px;
		margin: 1em
	}

	div.ps-form-buttons {
		text-align: center;
		margin:1em;
	}

	div.ps-form-panel {
		margin: 1.5em;
		/* display: table; */

		div.ps-form-panel-header {
			padding:0.4em;
			background-color:lightgray;
			border: 1px solid;
			border-bottom: 0px;
			font-weight: bold;
			border-radius: 0.5em 0.5em 0em 0em;
		}

		div.ps-form-panel-content {
			border: 1px solid;
			box-shadow:1;
			padding:1em;
			border-radius: 0em 0em 0.5em 0.5em;
		}
	}

	p.ps-form-input-container {
		display: block;

		label.ps-form-input-label {
			display: block;
		}

		input.ps-form-input {
			display: block;
		}
	}

	div.ps-form-checkbox-container {
		display: block;

		label.ps-form-checkbox-label {
			display: block;
		}

		input.ps-form-checkbox {
			display: block;
			width: 2em;
			height: 2em;
		}
	}


	fieldset.ps-form-list-container {

		div.ps-form-list-item-header {
			margin-top: 1em;
			padding:0.2em;
			border-bottom:0px;
			grid-column: 1 / -1;

			div.ps-form-list-item-number {
				display: inline-block;
				font-weight: bold;
				grid-column: 1;
			}
		}

		div.ps-form-list-item-content {
			padding:0.2em;
			border-top:0px;
			display: grid;
			grid-column: 1 / -1;
			border-bottom: 1px solid;
		}

		button.ps-form-list-add-btn {
			font-weight: bold;
			width: 100%;
		}

		button.ps-form-list-delete-btn {
			float:right;
			font-weight: normal;
		}
	}

	pre.ps-form-debug {
		width:fit-content;
	}


}
